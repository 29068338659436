var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Polish = {
    weekdays: {
      shorthand: ["Nd", "Pn", "Wt", "\u015Ar", "Cz", "Pt", "So"],
      longhand: ["Niedziela", "Poniedzia\u0142ek", "Wtorek", "\u015Aroda", "Czwartek", "Pi\u0105tek", "Sobota"]
    },
    months: {
      shorthand: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Pa\u017A", "Lis", "Gru"],
      longhand: ["Stycze\u0144", "Luty", "Marzec", "Kwiecie\u0144", "Maj", "Czerwiec", "Lipiec", "Sierpie\u0144", "Wrzesie\u0144", "Pa\u017Adziernik", "Listopad", "Grudzie\u0144"]
    },
    rangeSeparator: " do ",
    weekAbbreviation: "tydz.",
    scrollTitle: "Przewi\u0144, aby zwi\u0119kszy\u0107",
    toggleTitle: "Kliknij, aby prze\u0142\u0105czy\u0107",
    firstDayOfWeek: 1,
    time_24hr: true,
    ordinal: function () {
      return ".";
    }
  };
  fp.l10ns.pl = Polish;
  var pl = fp.l10ns;
  exports.Polish = Polish;
  exports.default = pl;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Polish = exports.Polish,
      __esModule = exports.__esModule;